import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import { Link } from 'gatsby'

import { useState, useEffect } from 'react'

export default function MediaCard({ title, image, text, path, darkMode }) {
	const bc = darkMode ? 'black' : 'white'
	const textColor = darkMode ? 'white' : 'black'

	const useStyles = makeStyles({
		root: {
			minWidth: '100%',
			borderRadius: 0,
		},
		media: {
			height: 0,
			paddingTop: '50%',
			marginTop: '30',
		},
		cardContent: {
			paddingLeft: 0,
			height: '40%',
			backgroundColor: bc,
			color: textColor,
		},
		linkStyle: {
			textDecoration: 'none',
		},
		readButton: {
			width: '100%',
			height: '6%',
			borderWidth: '1px',
			borderStyle: 'solid',
			backgroundColor: bc,
			color: textColor,
			justifyContent: 'center',
			'&:hover': {
				textDecoration: 'underline',
			},
		},
		noPaddingLeft: {
			paddingLeft: 0,
		},
		text: {
			fontWeight: 'bold',
		},
	})
	const classes = useStyles()

	const [img, setImg] = useState(null)

	useEffect(() => {
		setImg(image)
	}, [image])

	return (
		<Card className={classes.root} elevation={0}>
			{image && (
				<CardMedia
					className={classes.media}
					image={img}
					title={title}
				/>
			)}
			<CardContent className={classes.cardContent}>
				<p className={`title is-4 ${darkMode ? 'has-text-white' : ''}`}>
					{title}
				</p>
				<p
					className={`custom-card-content ${
						darkMode ? 'has-text-white' : ''
					}`}
				>
					{text}
				</p>
			</CardContent>
			<Link to={path} className={classes.linkStyle}>
				<CardActions className={classes.readButton}>
					Les mer
				</CardActions>
			</Link>
		</Card>
	)
}
