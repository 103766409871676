import React from 'react'
import Grid from '@material-ui/core/Grid'
import MediaCard from './MediaCard'

export default function NewsRoll({ posts, darkMode }) {
  return (
    <Grid container direction="row" justify="space-between" spacing={6}>
      {posts &&
        Array.from(posts).map((post) => (
          <Grid item xs={12} md={6} key={post.fields.slug}>
            <MediaCard
              title={post.frontmatter.title}
              text={post.excerpt}
              path={post.fields.slug}
              image={post.frontmatter.featuredimage.childImageSharp.fluid.src}
              darkMode={darkMode}
            />
          </Grid>
        ))}
    </Grid>
  )
}
