import React from 'react';

import { graphql, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import Layout from '../../components/Layout';
import NewsRoll from '../../components/NewsComponents/NewsRoll';
import PageWrapper from '../../components/PageWrapper';
import SEO from '../../components/SEO/SEO';

export default function NewsIndexPage() {
  const data = useStaticQuery(graphql`
      query NewsRollQuery {
          allMarkdownRemark(
              sort: { order: DESC, fields: [frontmatter___date] }
              filter: { frontmatter: { templateKey: { eq: "news-page" } } }
          ) {
              edges {
                  node {
                      excerpt(pruneLength: 300)
                      id
                      fields {
                          slug
                      }
                      frontmatter {
                          title
                          date(formatString: "MMMM DD, YYYY")
                          featuredpost
                          featuredimage {
                              childImageSharp {
                                  fluid(maxWidth: 2048, quality: 85) {
                                      ...GatsbyImageSharpFluid_withWebp
                                  }
                              }
                          }
                      }
                  }
              }
          }
      }

  `);

  NewsRoll.propTypes = {
    data: PropTypes.shape({
      allMarkdownRemark: PropTypes.shape({
        edges: PropTypes.array,

      }),
    }),
  };

  const posts = data.allMarkdownRemark.edges.map((x) => x.node);
  return (
    <Layout color="black">
      <SEO
        title="Nyheter"
      />
      <PageWrapper title="Nyheter">
        {posts
          ? <NewsRoll posts={posts} darkMode={false}/>
          : <h1>Det er ingen nyheter akkurat nå</h1>
        }
      </PageWrapper>
    </Layout>
  );
}
